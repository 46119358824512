var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Page"},[_c('v-row',[_vm._l((_vm.products),function(product,prs){return [((_vm.page - 1) * 12 <= prs && _vm.page * 12 > prs)?[_c('v-col',{key:prs,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"Product__card-similars mx-auto",attrs:{"elevation":"0","to":'/product/' + product.url,"ripple":false}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",class:{ 'on-hover-card': hover }},[_c('div',{staticClass:"py-5"},[(product.cover != null)?[_c('v-img',{staticClass:"d-block mx-auto Product__card-similars__image",attrs:{"contain":"","src":'https://www.cymcms.actstudio.xyz/' +
                        product.cover.path}})]:_vm._e()],2),_c('v-divider',{staticClass:"mx-12 my-3 divider-product"}),_c('v-card-text',[(product.style.name != 'Modern')?_c('span',{staticClass:"titles-big",class:{
                      'bree-serif': product.style.name == 'Transitional',
                      pacifico: product.style.name == 'Classic',
                      'montserrat-regular': product.style.name == 'Modern',
                    }},[_vm._v(_vm._s(product.name))]):_vm._e(),(product.style.name == 'Modern')?_c('span',{staticClass:"titles-big-modern-only",class:{
                      'bree-serif': product.style.name == 'Transitional',
                      pacifico: product.style.name == 'Classic',
                      'montserrat-regular': product.style.name == 'Modern',
                    }},[_vm._v(_vm._s(product.name))]):_vm._e()])],1)],1)],1)]}}],null,true)})],1)]:_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }