<template >
  <div class="Page">
    <v-row>
      <template v-for="(product, prs) in products">
        <template v-if="(page - 1) * 12 <= prs && page * 12 > prs">

        
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          :key="prs"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              elevation="0"
              class="Product__card-similars mx-auto"
              :to="'/product/' + product.url"
              :ripple="false"
            >
              <v-row no-gutters>
                <v-col class="text-center" :class="{ 'on-hover-card': hover }">
                  <div class="py-5">
                    <template v-if="product.cover != null">
                      <v-img
                        class="d-block mx-auto Product__card-similars__image"
                        contain
                        :src="
                          'https://www.cymcms.actstudio.xyz/' +
                          product.cover.path
                        "
                      ></v-img>
                    </template>
                  </div>
                  <v-divider class="mx-12 my-3 divider-product"></v-divider>
                  <v-card-text>
                    <span
                      v-if="product.style.name != 'Modern'"
                      class="titles-big"
                      v-bind:class="{
                        'bree-serif': product.style.name == 'Transitional',
                        pacifico: product.style.name == 'Classic',
                        'montserrat-regular': product.style.name == 'Modern',
                      }"
                      >{{ product.name }}</span
                    >
                    <span
                      v-if="product.style.name == 'Modern'"
                      class="titles-big-modern-only"
                      v-bind:class="{
                        'bree-serif': product.style.name == 'Transitional',
                        pacifico: product.style.name == 'Classic',
                        'montserrat-regular': product.style.name == 'Modern',
                      }"
                      >{{ product.name }}</span
                    >
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
        </template>
      </template>
    </v-row>
  </div>
</template>
<script >
export default {
  props: {
    products: Array,
    page: Number,
  },
};
</script>